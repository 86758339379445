<template>
  <div id="stageline-landing-page-v1-component">
    <notification-manager v-if="showNotification" />
    <h1 class="stageline-title mb-4 mt-5">
      Welcome to the Stageline&trade;
    </h1>

    <div class="stageline-info">
      Use our Stageline&trade; to help guide you through the various stages of starting and
      maintaining your business. Whether you've already formed your business or are just
      getting started, our free and easy-to-use tool is here to help you, one stage at a time.
    </div>

    <b-container>
      <ct-centered-spinner v-if="!loaded" style="min-height:200px;" />
    </b-container>

    <div v-if="loaded" class="d-flex select-or-add-company">
      <div id="image-left">
        <picture>
          <source
            :srcset="require('@images/illustrations/filing-options-halfpage.webp')"
            type="image/webp"
          >
          <img
            :src="require('@images/illustrations/filing-options-halfpage.png')"
            alt="Woman relaxing in chair"
            height="408"
            class="image"
          >
        </picture>
      </div>
      <div id="company-selection-right">
        <template v-if="companyCount > 0">
          <div v-if="companyCount <= 5" class="company-card-container">
            <selection-cards
              button-text="Continue"
              :items="accountCompanies"
              @chosen="view"
            />
          </div>
          <multi-select
            v-else
            :value="selectedCompany"
            :options="accountCompanies"
            :searchable="true"
            :allow-empty="false"
            track-by="id"
            label="name"
            placeholder="Select a company"
            @input="view"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.name }}
            </template>
          </multi-select>
          <div class="row-or">
            <div>or</div>
          </div>
        </template>
        <button class="btn btn-primary text-nowrap" @click="presentAddCompanyModal">
          <span v-if="companyCount < 1">Create a New Business</span>
          <span v-else>Create Another Business</span>
        </button>
      </div>
    </div>
    <div class="continue">
      Continue to
      <b-link :to="'/dashpanel'">
        &nbsp;Home
      </b-link>
    </div>

    <b-modal
      ref="add-company-modal"
      v-model="showAddCompanyModal"
      header-class="no-borders"
      hide-header
      hide-footer
      size="lg"
    >
      <add-company @create="createCompany" />
    </b-modal>

    <formation-info-modal
      ref="formation-info-modal"
      v-model="companyAdded"
      :company="company"
      :load-products="false"
      @formation-info-modal-complete="formationInfoModalCompleteHandler"
    />

    <company-domestic-registration-modal
      ref="company-domestic-registration-modal"
      :company="company"
      :load-products="false"
      @domestic-registration-changed="domesticRegistrationChangedHandler"
    />

    <capture-odd-data />
  </div>
</template>

<script>
import axiosClient from '../../http'
import AddCompany from '../../components/AddCompany'
import MultiSelect from 'vue-multiselect'
import FormationInfoModal from '@/components/StagelineV1/modals/FormationInfoModal'
import CompanyDomesticRegistrationModal from '@/components/CompanyDomesticRegistrationModal'
import SelectionCards from '@/components/shared/SelectionCards'
import { mapActions, mapGetters } from "vuex"
import CtCenteredSpinner from '../../components/shared/CtCenteredSpinner'
import CaptureOddData from '@/components/CaptureOddData'
import NotificationManager from "@/components/NotificationManager"

export default {
  name: 'StagelineLandingPageV1',
  components: {
    CaptureOddData,
    CompanyDomesticRegistrationModal,
    MultiSelect,
    FormationInfoModal,
    AddCompany,
    CtCenteredSpinner,
    SelectionCards,
    NotificationManager,
  },
  data () {
    return {
      selectedCompany: null,
      accountCompanies: false,
      showAddCompanyModal: false,
      loaded: false,
      companyAdded: false,
      domesticRegistration: false,
      showingDomesticRegistrationModal: false,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'company',
      'stagelineComplete',
      'isFormationFiling',
      'showFormationStageline',
      'startOver',
    ]),
    ...mapGetters('companies', [
      'formedElsewhere',
      'formationInfoObtained',
    ]),
    companyCount() {
      return this.accountCompanies.length || 0
    },
    showNotification() {
      return !this.loading
    },
  },
  async mounted() {
    if (this.startOver) {
      this.presentCompanyDomesticRegistrationModal()
    }
    await this.fetchAccountCompanies()
    this.resetStageline(this.startOver)

    this.loaded = true
  },
  methods: {
    ...mapActions('companies', [
      'updateDomesticRegistrationJurisdiction',
      'setCurrentCompany',
    ]),
    ...mapActions('stageline', [
      'resetStageline',
      'setStartOver',
    ]),
    ...mapActions('checkout', ['loadProducts']),
    async view(company) {
      await this.setCurrentCompany({ id: company.id, force: false })

      if (this.$refs['add-company-stageline-modal']) {
        this.$refs['add-company-stageline-modal'].hide()
      }

      if (!this.company?.domestic_registration) {
        this.presentCompanyDomesticRegistrationModal()
      } else if (!this.formationInfoObtained) {
        this.presentFormationInfoModal()
      } else {
        this.selectStageline()
      }
    },
    formationInfoModalCompleteHandler() {
      if (!this.company.domestic_registration) {
        this.presentCompanyDomesticRegistrationModal()
      }
      this.selectStageline()
    },
    domesticRegistrationChangedHandler() {
      // TODO some of this formStagelineComplete logic seems to be redundant
      if (this.stagelineComplete('form')) {
        this.view()
      } else if (!this.formationInfoObtained) {
        this.presentFormationInfoModal()
      } else {
        this.selectStageline()
      }
    },
    presentCompanyDomesticRegistrationModal() {
      this.$refs['company-domestic-registration-modal'].show()
    },
    selectStageline() {
      if(!this.stagelineComplete('business_info') || this.startOver) {
        this.setStartOver(false)
        this.$router.push({ name: 'stageline', params: { companyId: this.company.id, stagelineType: "business_info" } })
      } else if (this.showFormationStageline) {
        this.$router.push({ name: 'stageline', params: { companyId: this.company.id, stagelineType: "form" } })
      } else {
        this.$router.push({ name: 'stageline-page', params: { companyId: this.company.id } })
      }
    },
    presentAddCompanyModal() {
      this.showAddCompanyModal = true
    },
    presentFormationInfoModal() {
      this.$refs['formation-info-modal'].show()
    },
    async createCompany(companyName, entityTypeId) {
      const record = { name: companyName, entity_type_id: entityTypeId }
      const response = await axiosClient.post('client/companies', {
        company: record,
      })
      await this.fetchAccountCompanies()

      this.$refs['add-company-modal'].hide()

      await this.view(response.data.result)
    },
    async fetchAccountCompanies() {
      const response = await axiosClient.get('client/companies/index_simple', { params: { limit: 1000 } })
      if (response.data.result.length) {
        this.accountCompanies = response.data.result
      }
    },
  },
}
</script>

<style lang="scss" scoped>

#stageline-landing-page-v1-component {
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-left: 12%;
  margin-right: 12%;

  .website-name {
    position: absolute;
    top: 20px;
    left: 20px;
    margin-top: 60px;
    color: $informational;

    @media only screen and (max-width: 769px) {
      display: none;
    }
  }

  .stageline-title {
    font-size: 3em;

    @media only screen and (max-width: 550px) {
      font-size: 2.5em !important;
    }
  }

  .stageline-info {
    color: $informational;
    padding-bottom: 1.5rem;
  }

  .select-or-add-company {
    min-height: 300px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    #company-selection-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      .company-card-container,
      .multiselect {
        width: 500px;
      }
    }

    .multiple-product-selection {
      .main {
        padding: 10px 20px 10px 20px;
      }
    }

    h4 {
      margin-bottom: 1rem;
    }
    margin: 0 auto;

    .multiselect {
      margin-top: 1.5em !important;
    }
    .multiselect__tags {
      border: 1px solid black; //TODO: This text should be teal after design team stuff
    }

    .col-or {
      margin: 0 3rem;
      width: 1px;
      background-color: $ct-ui-font-color-secondary;
    }

    .row-or {
      margin: 2rem 0;
      width: 35%;
      height: 1px;
      background-color: $ct-ui-font-color-secondary;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      div {
        color: $ct-ui-font-color-secondary;
        padding: 20px;
        background: white;
        text-align: center;
      }
    }

    .new-business {
      &.has-company {
        max-width: 300px;
      }
      &.no-companies {
      }
      p {
        color: $informational;
      }
    }
  }

  button {
    padding: .4rem 1.25rem;
    font-weight: bold;
  }

  .continue {
    margin-top: 5rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 1282px) {
    #image-left {
      picture {
        img {
          height: 308px;
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    #image-left {
      display: none;
    }
    @media only screen and (max-height: 850px) {
      .select-or-add-company {
        min-height: 200px !important;
      }
    }
  }

  @media only screen and (max-height: 850px) {
    #image-left {
      picture {
        img {
          height: 308px;
        }
      }
    }
    .continue {
      margin-top: 1rem !important;
    }
  }

  @media only screen and (max-width: 760px) {
    .company-card-container,
    .multiselect {
      width: 100% !important;
    }
  }
}
</style>
